import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { initializeIcons } from '@fluentui/react';
import * as Msal from '@azure/msal-browser';
import Unauthenticated from './signIn/Unauthenticated';
import SigningIn from './signIn/SigningIn';
import App from './App';
import { initializeApi, Api } from './api/Api';
import { Provider } from 'react-redux';
import { USER_SIGNED_IN } from './state/actions/UserActions';
import configureStore from './state/Store';
import { HashRouter } from 'react-router-dom';
import { appConfig } from './config/AppConfigProxy';

export const ensureHttps = () => {
    const https = 'https';
    const { host, pathname, protocol } = document.location!;
    if (appConfig.apiUrl.startsWith(https) && 
        !protocol.startsWith(https)) {
        document.location!.replace(`${https}://${host}${pathname}`);
    }
};

ensureHttps();

declare var module: any;

initializeIcons();
const store = configureStore();
const root = document.getElementById('root');

const msalConfig: Msal.Configuration = {
    auth: {
        clientId: appConfig.clientApplicationId
    },
    cache: {
        cacheLocation: 'localStorage'
    }
};

const msal = new Msal.PublicClientApplication(msalConfig);

const scopes = [`api://${appConfig.clientApplicationId}/access_as_user`];

let accountId = '';

function makeAuthParams(): Msal.RedirectRequest {
    const account = msal.getAccountByHomeId(accountId) || undefined;
    return {
        scopes,
        account
    };
}

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

let getTokenCallId = 0;
let getTokenInProgressId = 0;
export const getToken = async (allowNull?: boolean): Promise<string> => {
    const callId = ++getTokenCallId;
    for (let waitCounter = 0; getTokenInProgressId && waitCounter < 50; waitCounter++) {
        if ((waitCounter % 10) === 1) { // change 1 to 0 to log first time around; happens quite a bit though, so only logging from second time by default
            console.log(`getToken(${callId}): ${getTokenInProgressId} in progress, waiting (${waitCounter})...`);
        }
        await delay(100);
    }
    if (getTokenInProgressId) {
        console.warn(`getToken(${callId}): waited too long`);
    } else {
        try {
            getTokenInProgressId = callId;
            for (let attempt = 0; attempt < 2; attempt++) {
                if (attempt > 0) {
                    await delay(250);
                }
                try {
                    const response = await msal.acquireTokenSilent(makeAuthParams());
                    const token = response.accessToken;
                    if (token) {
                        return token;
                    }
                    console.log(`getToken(${callId}): encountered null accessToken (attempt ${attempt + 1})`);
                } catch (e) {
                    console.warn(`getToken(${callId}): unable to acquire token silently (attempt ${attempt + 1})`, e);
                }
            }
            console.log(`getToken(${callId}): unable to acquire token`);
        } finally {
            getTokenInProgressId = 0;
        }
    }
    if (!allowNull) {
        window.location.reload();
    }
    return "";
};

const getInitialToken = async () => {
    try {
        const response = await msal.handleRedirectPromise();
        if (response) {
            accountId = response.account?.homeAccountId || '';
            console.log(`getInitialToken: response has account ${accountId}`);
            return response.accessToken;
        }

        const currentAccounts = msal.getAllAccounts();
        if (!currentAccounts || currentAccounts.length < 1) {
            console.log('getInitialToken: no current accounts');
            return null;
        }
        
        if (currentAccounts.length > 1) {
            console.log('getInitialToken: multiple current accounts, using first');
        }

        accountId = currentAccounts[0].homeAccountId;
        console.log(`getInitialToken: existing account ${accountId}`);

        const token = await getToken(true);
        return token;
    } catch (e) {
        console.warn('getInitialToken: error', e);
        return null;
    }
};

const renderSigningIn = async () => {
    ReactDOM.render(<SigningIn />, root);
};

const renderApp = async (token: string) => {
    try {
        const signedInUser = await Api.signIn(token);

        if (!signedInUser || !signedInUser.hasClientLogin) {
            throw new Error('User does not have ClientLogin role.');
        }

        initializeApi(signedInUser, getToken);    
        store.dispatch({ 
            type: USER_SIGNED_IN,
            payload: signedInUser
        });            

        ReactDOM.unmountComponentAtNode(root!);
        ReactDOM.render(
            <Provider store={store}>
                <HashRouter >
                    <App />
                </HashRouter>
            </Provider>,
            root
        );    

        if (module.hot) {
            module.hot.accept('./App', () => {
                const NextApp = require('./App').default;
                ReactDOM.render(
                    <Provider store={store}>
                        <HashRouter>
                            <NextApp user={signedInUser} />
                        </HashRouter>
                    </Provider>,
                    root
                );   
            });
        }
    } catch (e) {
        console.error(e);
        renderUnauthenticated(true);
    }
};

const renderUnauthenticated = (authenticationFailed = false) => {

    localStorage.clear();
    sessionStorage.clear();

    ReactDOM.unmountComponentAtNode(root!);
    ReactDOM.render(
        <Unauthenticated 
            onSignInClick={() => msal.loginRedirect(makeAuthParams())} 
            authenticationFailed={authenticationFailed}
        />,
        root
    );
}

renderSigningIn();

getInitialToken().then(token => {
    if (token) {
        renderApp(token);
    } else {
        console.log('getInitialToken returned null');
        renderUnauthenticated();
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();