import * as React from 'react';
import { Nav, INavLink } from '@fluentui/react';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ISiteMapPage } from '../siteMap/ISiteMapPage';
import { IUserAppState } from '../state/models/IUserAppState';
import SiteMap from '../siteMap/SiteMap';
import { theme } from '../theme';
import { IAppState } from '../state/IAppState';

interface IProps extends RouteComponentProps {
    page: ISiteMapPage;
    userAppState: IUserAppState;
}

class MainNav extends React.Component<IProps> {

    public render() {
        return (
            <Nav
                groups={[
                    {
                        links: [
                            this.getLink(SiteMap, true)!,
                            ...this.getLinks(SiteMap.children!)
                        ]
                    }
                ]}
                selectedKey={this.getSelectedKey(this.props.page)}
                styles={{
                    compositeLink: {
                        backgroundColor: 'tranparent'
                    },
                    link: {
                        backgroundColor: 'rgb(255, 255, 255, 0.2)',
                        color: theme.palette.white,
                        selectors: {
                            '&:hover': {
                                backgroundColor: 'rgb(255, 255, 255, 0.3) !important',
                                color: theme.palette.white + ' !important'
                            },
                            '.is-selected &': {
                                backgroundColor: 'rgb(255, 255, 255, 0.4) !important'
                            },
                            '& .ms-Button-icon': {
                                margin: '0 15px'
                            }
                        }
                    },

                }}
            />
        );
    }

    private getLinks = (pages: ISiteMapPage[]) => {
        const links: INavLink[] = [];
        pages.forEach(page => {
            const link = this.getLink(page);
            if (link) {
                links.push(link);
            }
        });

        return links;
    }

    private getLink = (page: ISiteMapPage, ignoreChildren?: boolean): INavLink => {
        const currentPage = this.props.page;

        return {
            key: page.paths[0],
            name: page.name,
            url: page.paths[0],
            links: (!ignoreChildren && page.children) ? this.getLinks(page.children.filter(o => o.showOnMainNav)) : undefined,
            onClick: (ev?: React.MouseEvent<HTMLElement>) => {
                if (ev) {
                    ev.preventDefault();
                }
                this.props.history.push(page.paths[0]);
            },
            iconProps: page.iconName ? { iconName: page.iconName } : undefined,
            isExpanded: !!page.expandChildren || this.getIsExpanded(page, currentPage),
        }
    };

    private getIsExpanded = (page: ISiteMapPage, currentPage: ISiteMapPage): boolean => {
        let expanded = false;
        if (page && currentPage) {
            if (page.name === currentPage.name) {
                expanded = true;
            }

            if (!expanded && page.children) {
                for (const child of page.children) {
                    expanded = this.getIsExpanded(child, currentPage);
                    if (expanded) {
                        break;
                    }
                }
            }
        }

        return expanded;
    }

    private getSelectedKey = (page?: ISiteMapPage) => {
        while (page && !page.showOnMainNav && page.parent) {
            page = page.parent;
        }

        return page ? page.paths[0] : '/';
    }

}

export default connect(
    (state: IAppState) => ({
        page: state.page,
        userAppState: state.userAppState
    })
)(withRouter(MainNav)); 
