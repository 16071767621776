import * as React from 'react';
import AppName from './AppName';
import Breadcrumb from './Breadcrumb';
import UserPanel from './UserPanel';
import CommandBar from './CommandBar';
import SiteMapRoutes from '../siteMap/SiteMapRoutes';
import MainNav from './MainNav';
import { ISettingsState } from '../state/models/ISettingsState';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import Information from './Information';

interface IProps { 
    settings: ISettingsState;
}

interface IState { }

class SiteLayout extends React.Component<IProps, IState> {

    public state = {} as IState;

    public render = () => {
        return (
            <div className={`container ${this.props.settings.additionalPanelOpen ? 'additional-open' : 'additional-closed'}`}>
                <div className="header">
                    <div className="app-name">
                        <AppName />
                    </div>
                    <div className="breadcrumb">
                        <Breadcrumb />
                    </div>
                    <div className="user">
                        <UserPanel />
                    </div>
                </div>
                <div className="command-bar">
                    <CommandBar />
                </div>
                <div className="menu">
                    <MainNav />
                </div>
                <div className="content">
                    <SiteMapRoutes />                                                              
                </div>
                <div className="additional">
                    <Information />
                </div>
                <div className="footer">
                    <div className="copyright">
                        &copy; Copyright {new Date().getFullYear()} Inde Technology Ltd
                    </div>
                </div>                
            </div>
        );
    }
}

export default connect((state: IAppState) => ({ settings: state.settings }))(SiteLayout);