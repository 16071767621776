import * as React from 'react';
import { mergeStyleSets } from '@fluentui/react';
import { theme } from '../theme';
import { Icon } from '@fluentui/react';
import { FontSizes } from '@fluentui/react';

interface IProps { }

export default class AppName extends React.Component<IProps> {

    public render = () => {
        return (
            <div className={css.container}>
                <div className={css.menuButton}>
                    <Icon iconName="WaffleOffice365" />
                </div>
                <div className={css.name}>
                    <img src={require('../images/logo-inverse.png').default} height={30} alt="" />
                </div>
            </div>
            
        );
    }
}


const css = mergeStyleSets({
    container: {
        display: 'flex',
        lineHeight: 50,
        color: theme.palette.white
    },
    menuButton: {
        minWidth: 50,
        height: 50,
        background: theme.palette.themeDarkAlt,
        fontSize: FontSizes.size24,
        textAlign: 'center'
    },
    name: {
        padding: '10px 0px 10px 10px'        
    }
});