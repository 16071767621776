import * as React from 'react';
import FormBase, { IFormBaseProps, IFormBaseState } from './FormBase';
import { PrimaryButton, DefaultButton, IButtonProps, IButton } from '@fluentui/react';
import { Spinner } from '@fluentui/react';
import { theme } from '../theme';

interface IModel {
    id: number;
}

interface IFormProps<TModel extends IModel> extends IFormBaseProps<TModel, TModel> {
    onModelSaved: (model: TModel) => void;
    onCancel?: () => void;
    onRenderSaveButton?: (save: () => Promise<TModel | null>, props: IButtonProps, defaultRender?: (props: IButtonProps) => React.ReactNode) => React.ReactNode;   
    onRenderCancelButton?:  (props: IButtonProps, defaultRender?: (props: IButtonProps) => React.ReactNode) => React.ReactNode;
}

export default class Form<
    TModel extends IModel
> extends FormBase<
    IFormProps<TModel>, 
    IFormBaseState<TModel>, 
    TModel, 
    TModel
> {

    private saveButtonRef: React.RefObject<IButton> | null = null;

    constructor(props: IFormProps<TModel>) {
        super(props);

        this.save = this.save.bind(this);
    }

    public render() {
        return this.renderSave();
    }

    private renderSave = () => {
        const { loading } = this.state;

        return (
            <>
                {super.render()}
                {/* <pre>{JSON.stringify(this.state.modified, null, 4)}</pre> */}
                {!this.props.disabled &&
                    <div style={{ marginTop: theme.spacing.l1 }}>
                        {loading && 
                            <PrimaryButton>
                                Saving&nbsp;<Spinner />
                            </PrimaryButton>
                        }
                        {!loading &&
                            <>
                                {this.renderSaveButton()}
                                {this.props.onCancel && this.renderCancelButton()}
                            </>
                        }
                    </div> 
                }
            </>           
        );        
    }

    private renderCancelButton = () => {
        const { onRenderCancelButton, onCancel } = this.props;
        const props: IButtonProps = {
            onClick: onCancel,
            styles: { root: { marginLeft: theme.spacing.m } },
            text: 'Cancel'
        };

        const render = (props: IButtonProps) => {
            return <DefaultButton {...props} />;
        }

        if (onRenderCancelButton) {
            return onRenderCancelButton(props, render);
        }

        return render(props);
    }

    private renderSaveButton = () => {
        this.saveButtonRef = React.createRef<IButton>();
        const { onRenderSaveButton } = this.props;
        const props: IButtonProps = {
            componentRef: this.saveButtonRef,
            onClick: this.save,
            text: 'Save'
        };

        const render = (props: IButtonProps) => {
            return <PrimaryButton {...props} />;
        };

        if (onRenderSaveButton) {
            return onRenderSaveButton(this.save, props, render);
        }

        return render(props);
    }                           

    private async save() {
        const { modified } = this.state;

        this.setState({ loading: true, errors: undefined });

        try {
            let result: TModel;

            result = await this.props.repository.saveAsync(modified);

            this.props.onModelSaved(result);

            this.setState({
                loading: false,
                modified: super.getDeepCopy(result),
                original: result
            });

            return result;
                        
        } catch (e) {
            let errors: any = e;

            // Sometimes errors can be nested as errors.errors.
            if (errors.hasOwnProperty('errors')) {
                errors = errors.errors;
            }

            if (errors.hasOwnProperty('errors')) {
                errors = errors.errors;
            } else if (
                errors.hasOwnProperty('message') &&
                typeof(errors.message) === 'string'
            ) {
                errors = errors.message;
            }

            this.setState({ errors, loading: false });
        }

        return null;
    }
}