import { ISettingsState } from "../models/ISettingsState";
import { IAction } from "../actions/IAction";
import { SET_ADDITIONAL_PANEL_OPEN } from "../actions/SettingsActions";

const getStorageKey = (key: keyof ISettingsState) => `settings::${key}`;

const getDefaultSettingsState = (): ISettingsState => {
    const value = localStorage.getItem(getStorageKey('additionalPanelOpen'));
    return {
        additionalPanelOpen: value !== 'false'
    }
}

const SettingsReducer = (state: ISettingsState = getDefaultSettingsState(), action: IAction<Partial<ISettingsState> | boolean>): ISettingsState => {
    switch(action.type) {
        case SET_ADDITIONAL_PANEL_OPEN:
            localStorage.setItem(getStorageKey('additionalPanelOpen'), action.payload + '');
            return {
                ...state,
                additionalPanelOpen: action.payload as boolean
            };
    }

    return state;
}

export default SettingsReducer;
