import React from 'react';
import { Dialog } from '@fluentui/react';
import Logo from './images/logo.png';
import { theme } from './theme';
import IndeTheme from './IndeTheme';

interface IProps {
    children: React.ReactNode;
}

const SignIn = (props: IProps) => 
    <IndeTheme>
        <Dialog 
            hidden={false}
            title="Inde Customer Portal"
            modalProps={{
                isBlocking: true
            }}
        >
            <div
                style={{
                    marginBottom: theme.spacing.m
                }}
            >
                <img src={Logo} height={50} alt="" />
            </div>
            {props.children}
        </Dialog>   
    </IndeTheme>

export default SignIn;

