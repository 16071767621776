import * as React from 'react';
import { CommandBar as MsCommandBar, ICommandBarProps, ICommandBarItemProps, FontWeights } from '@fluentui/react';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { theme } from '../theme';
import JobEditPanel from '../controls/jobs/JobEditPanel';
import { IJobModel } from '../api/models/IJobModel';
import { RouteComponentProps, withRouter } from 'react-router';
import { getJobEditDefault } from '../api/models/defaults/IJobModelDefault';
import { IAppUser } from '../api/models/IAppUser';

interface IProps extends RouteComponentProps { 
    commandBar: ICommandBarProps;
    appUser: IAppUser;
}

class CommandBar extends React.Component<IProps> {

    private jobEditPanelRef: JobEditPanel | null = null;

    public render = () => {

        const { commandBar } = this.props;
        const farItems: ICommandBarItemProps[] = [
            ...commandBar.farItems || [],
            {
                key: 'newJob',
                text: 'New job',
                iconProps: {
                    iconName: 'TaskLogo'
                },
                onClick: this.newJob,
                buttonStyles: {
                    label: {
                        fontWeight: FontWeights.semibold
                    }
                }
            }
        ];

        return (
            <>
                <MsCommandBar 
                    {...commandBar} 
                    farItems={farItems}
                    styles={{
                        root: {
                            backgroundColor: theme.palette.neutralLighter,
                            selectors: {
                                '& .ms-Button--commandBar': {
                                    backgroundColor: theme.palette.neutralLighter
                                },
                                '& .ms-Button--commandBar:hover': {
                                    backgroundColor: theme.palette.neutralLight
                                }                            
                            }
                        },
                    }}
                />
                <JobEditPanel 
                    ref={ref => this.jobEditPanelRef = ref}
                    onJobSaved={this.jobSaved}
                />                   
            </>
        );
    }

    private newJob = () => {
        const job = getJobEditDefault({ 
            companyId: this.props.appUser.companyId,
            requestorContactId: this.props.appUser.contactId 
        });        

        this.jobEditPanelRef!.editJob(job);
    }    

    private jobSaved = (job: IJobModel) => this.props.history.push(`/jobs/${job.id}/${job.name}`);
}

const connected = connect(
    (state: IAppState) => ({ 
        appUser: state.userAppState.signedInUser!,
        commandBar: state.commandBar 
    })
)(CommandBar);

const routed = withRouter(connected);

export default routed;