import { loadTheme, createTheme, ITheme } from "@fluentui/react";
import { FontSizes } from "@fluentui/react";
import { IButtonStyles } from "@fluentui/react";

interface ICustomTheme extends ITheme {
    custom: {
        buttons: {
            bulletActionButtonStyles: IButtonStyles
        },
        responsiveBreakPoints: {
            maxWidth600: string,
            minWidth1800: string,
            maxWidth1024: string
        }
    }
}

const palette = {
    themePrimary: '#e66625',
    themeLighterAlt: '#fef8f5',
    themeLighter: '#fbe4d9',
    themeLight: '#f7ceb9',
    themeTertiary: '#f09f77',
    themeSecondary: '#e9763d',
    themeDarkAlt: '#cf5b21',
    themeDark: '#ae4d1c',
    themeDarker: '#813915',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralQuaternaryAlt: '#dadada',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c8c8',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#ffffff',
}

const fabricTheme = createTheme({ palette });
loadTheme(fabricTheme);

export const getBulletButtonStyles = (theme: ITheme): IButtonStyles => ({
    root: {
        padding: '8px 5px',
        height: 30,
        color: theme.palette.black,
        background: theme.palette.neutralLight,
        borderRadius: 4
    },
    icon: {
        fontSize: FontSizes.size12,
        color: theme.palette.black
    },
    iconHovered: {
        color: theme.palette.black
    },
    rootHovered: {
        background: theme.palette.neutralQuaternary,
        color: theme.palette.black
    }
});

export const theme: ICustomTheme = {
    ...fabricTheme,
    custom: {
        buttons: {
            bulletActionButtonStyles: getBulletButtonStyles(fabricTheme)
        },
        responsiveBreakPoints: {
            maxWidth600: '@media only screen and (max-width: 600px)',
            minWidth1800: '@media only screen and (min-width: 1800px)',
            maxWidth1024: '@media only screen and (max-width: 1024px)'
        }
    }
};

