import * as React from 'react';
import { FontSizes, FontWeights, mergeStyleSets } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';

interface IPropertyViewProps {
    label: string;
    content?: React.ReactNode;
}

const PropertyView = (props: IPropertyViewProps) => (
    <div className={css.container}>
        <div className={css.label}>
            {props.label}
        </div>
        <div className={css.content}>
            {props.content === undefined ? <Shimmer /> : props.content}
        </div>
    </div>
);

const css = mergeStyleSets({
    container: {
        display: 'flex', 
        marginBottom: 15, 
        paddingBottom: 10, 
        borderBottom: 'dotted 1px silver',
        fontSize: FontSizes.medium
    },
    label: {
        minWidth: 200,
        paddingRight: 25,
        fontWeight: FontWeights.semibold as any,
        boxSizing: 'border-box'
    },
    content: {
        flex: 1
    }
});

export default PropertyView;