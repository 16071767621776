import * as React from 'react';
import SignIn from '../SignIn';
import { DialogFooter, PrimaryButton, MessageBar, MessageBarType } from '@fluentui/react';
import IndeTheme from '../IndeTheme';

interface IProps {
    onSignInClick: () => void;
    authenticationFailed?: boolean;
}

const Unauthenticated = (props: IProps) => 
    <IndeTheme>
        <SignIn>
            {props.authenticationFailed &&
                <MessageBar messageBarType={MessageBarType.error}>
                    There was an error signing in, please ensure you are 
                    setup with the 'Client login' role.
                </MessageBar>
            }
            <DialogFooter>
                <PrimaryButton onClick={props.onSignInClick}>
                    Sign in
                </PrimaryButton>                    
            </DialogFooter>            
        </SignIn>
    </IndeTheme>

export default Unauthenticated;