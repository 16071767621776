import * as React from 'react';
import { RepositoryBase } from './RepositoryBase';
import { Loading } from '../layout/Loading';

interface IModel {
    id: number;
}

interface IProps<TModel extends IModel> {
    id: number;
    repository: RepositoryBase<TModel>;
    onRenderModel?: (model: TModel) => React.ReactNode;
    onModelLoaded?: (model: TModel) => void;
    loadingTemplate?: React.ReactNode;
}

interface IState<TModel extends IModel> {
    model?: TModel;
}

export default class ApiGet<TModel extends IModel> extends React.Component<IProps<TModel>, IState<TModel>> {

    public state = {} as IState<TModel>;
    
    private abortController = new AbortController();

    public async componentDidMount() {
        await this.get(this.props.id);
    }

    public componentWillUnmount() {
        this.abortController.abort();
    }

    public get = async (id: number) => {

        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        this.setState({ model: undefined });

        const init = { signal: this.abortController.signal };
        const model = await this.props.repository.getAsync(id, init);

        if (model && this.props.onModelLoaded) {
            this.props.onModelLoaded(model);
        }

        this.setState({ model });
    }

    public render() {
        const { model } = this.state;

        if (model) {
            if (this.props.onRenderModel) {
                return this.props.onRenderModel(model);
            }

            return <div />;
        }

        return this.props.loadingTemplate || <Loading />;
    }

}