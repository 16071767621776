import * as React from 'react';
import FormField, { IFormFieldProps } from '../FormField';
import { ITextFieldProps, TextField } from '@fluentui/react';
import { IRenderFormFieldProps } from '../FormField';
import { IModel } from '../../api/models/IModel';

interface IProps<TModel extends IModel, TKey extends keyof TModel> 
    extends IFormFieldProps<TModel, TKey>, ITextFieldProps {    
}

export default class FormTextField<
    TModel extends IModel, 
    TKey extends keyof TModel
> extends React.Component<IProps<TModel, TKey>> {
    public render() {
        return (
            <FormField
                {...this.props}
                renderField={(fieldProps: IRenderFormFieldProps<TModel, TKey>) => (
                    <TextField 
                        defaultValue={fieldProps.value && fieldProps.value + ''} 
                        errorMessage={fieldProps.error} 
                        autoAdjustHeight={true}
                        onBlur={e => fieldProps.onValueChanged(e.target.value as any)}
                        {...this.props}
                    />
                )}
            /> 
        );
    }
}