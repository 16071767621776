import * as React from 'react';
import ModelComboBoxBase, { IModelComboBoxProps, IModelComboBoxOption } from '../ModelComboBoxBase';
import { IProjectModel, ProjectStatus } from '../../api/models/IProjectModel';
import { getApi } from '../../api/Api';
import { IProjectSearch } from '../../api/search/IProjectSearch';
import { ISelectableOption, mergeStyleSets, FontWeights } from '@fluentui/react';

export default class ProjectComboBox extends ModelComboBoxBase<
    IProjectSearch, 
    IProjectModel, 
    IModelComboBoxProps<IProjectSearch, IProjectModel>
> {

    protected repository = getApi().projects;

    protected getOptions = (models: IProjectModel[]) => models
        .map(model => ({
            model,
            key: model.id,
            text: model.name
        }))
        .sort((a, b) => a.text.localeCompare(b.text));

    protected getSearch = () => {
        return {
            status: ProjectStatus.Approved,
            ...super.getSearch()
        }
    }

    protected renderComboBox = (props?: Partial<IModelComboBoxProps<IProjectSearch, IProjectModel>>) => super.renderComboBox({
        ...props,
        onRenderOption: this.renderOption
    });

    private renderOption = (option?: ISelectableOption) => {
        if (option) {
            const { model } = (option as IModelComboBoxOption<IProjectModel>);
            return (
                <div>{model.name} - <span className={css.id}>P{model.id}</span></div>
            );
        }

        return null;
    }
}

const css = mergeStyleSets({
    id: {
        fontWeight: FontWeights.semibold
    }
});