import * as React from 'react';
import { Breadcrumb as MsBreadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { theme } from '../theme';
import { FontSizes } from '@fluentui/react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ISiteMapPage } from '../siteMap/ISiteMapPage';
import { IAppState } from '../state/IAppState';
import { connect } from 'react-redux';
import * as H from 'history';

interface IProps extends RouteComponentProps {
    page: ISiteMapPage;
}

const Breadcrumb = (props: IProps) => 
    <MsBreadcrumb 
        styles={{
            root: {
                color: theme.palette.white,
                lineHeight: '50px !important',
                margin: '0 0 0 15px',
                padding: 0,
                selectors: {
                    '& li:last-child .ms-Breadcrumb-item': {
                        color: theme.palette.white
                    }
                }
            },
            item: {
                lineHeight: '50px !important',
                color: theme.palette.white,
                fontSize: FontSizes.size16,
                selectors: {
                    ':active': activeHover,
                    ':hover': activeHover,
                    ':active:hover': activeHover
                }
            },
            itemLink: {
                lineHeight: '50px !important',
                color: theme.palette.white,
                fontSize: FontSizes.size16,
                selectors: {
                    ':active': activeHover,
                    ':hover': activeHover,
                    ':active:hover': activeHover
                }
            },
            chevron: {
                color: theme.palette.whiteTranslucent40
            }
        }}
        items={getItems(props)}
    />

const getItems = (props: IProps): IBreadcrumbItem[] => {

    let page: ISiteMapPage | undefined = props.page;

    const items: IBreadcrumbItem[] = [];

    while(page) {
        items.unshift({
            key: page.paths[0],
            text: page.title || page.name,
            onClick: page !== props.page ? gotoPage(page, props.history) : undefined
        })
        page = page.parent
    }

    return items;
}

const activeHover = {
    backgroundColor: 'none',
    color: theme.palette.whiteTranslucent40
}

const gotoPage = (page: ISiteMapPage, history: H.History) => () => history.push(page.paths[0]);

const routed = withRouter(Breadcrumb);

const connected = connect((state: IAppState) => ({ page: state.page}))(routed);

export default connected;