import * as React from 'react';

interface IProps {
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

export const H1: React.FunctionComponent<IProps> = props => <h1 className="ms-font-xxl" style={props.style}>{props.children}</h1>;

export const H2: React.FunctionComponent<IProps> = props => <h2 className="ms-font-xl" style={props.style}>{props.children}</h2>;

export const H3: React.FunctionComponent<IProps> = props => <h3 className="ms-font-lg" style={props.style}>{props.children}</h3>;