import { IComboBoxProps, ComboBox, IComboBoxOption } from "@fluentui/react";
import React from "react";
import { RepositoryBase } from "../api/RepositoryBase";

interface IModel {
    id: number;
}

export interface IModelComboBoxOption<TModel extends IModel> extends IComboBoxOption {
    model: TModel;
}

export interface IModelComboBoxProps<TSearch, TModel extends IModel> extends Partial<IComboBoxProps> { 
    search?: TSearch;
    onModelChange?: (model?: TModel, index?: number) => void;
    selectedModel?: TModel;
}

export interface IModelComboBoxState<TModel extends IModel> {
    models?: TModel[];
}

export default abstract class ModelComboBoxBase<
    TSearch,
    TModel extends IModel, 
    TProps extends IModelComboBoxProps<TSearch, TModel>,
    TState extends IModelComboBoxState<TModel> = {}
> extends React.Component<TProps, TState> {

    public state = {} as TState;

    protected abstract repository: RepositoryBase<TModel>;

    public componentDidMount = async () => {
        const models = await this.repository.searchAsync(this.props.search);
        this.setState({ models });

        if (models.length === 1 && this.props.onModelChange) {
            this.props.onModelChange(models[0]);
        }
    }

    public render = () => {

        const { models } = this.state;

        if (!models) {
            return this.renderComboBox({ placeholder: 'Loading...' })
        }

        const options = this.getOptions(models);

        return this.renderComboBox({ 
            options,
            autoComplete: 'on',
            allowFreeform: true
        });
    }

    protected getSearch(): TSearch {
        return {
            ...this.props.search
        } as TSearch;
    }

    protected renderComboBox(props?: Partial<IModelComboBoxProps<TSearch, TModel>>) {
        return (
            <ComboBox
                placeholder="Choose an option"
                onChange={this.comboBoxChange}
                selectedKey={this.props.selectedModel && this.props.selectedModel.id}
                {...(this.props as IComboBoxProps)}
                {...props}
            />
        );
    }

    protected abstract getOptions: (models: TModel[]) => IModelComboBoxOption<TModel>[];

    protected comboBoxChange = (e: any, optionInput?: IComboBoxOption, index?: number) => {
        const option = optionInput as IModelComboBoxOption<TModel>;
        return this.props.onModelChange && this.props.onModelChange(option && option.model, index);
    }
}