import { IAction } from "../actions/IAction";
import { PAGE_CHANGE, SET_PAGE_TITLE } from "../actions/PageActions";
import SiteMap from "../../siteMap/SiteMap";
import { ISiteMapPage } from "../../siteMap/ISiteMapPage";

const PageReducer = (state: ISiteMapPage = {...SiteMap}, action: IAction<ISiteMapPage | string>): ISiteMapPage => {
    switch(action.type) {
        case PAGE_CHANGE: 
            return {...action.payload as ISiteMapPage};

        case SET_PAGE_TITLE:
            return {
                ...state,
                title: action.payload as string
            }
    }

    return state;
}

export default PageReducer;
