import * as React from 'react';
import { theme } from './theme';
import { mergeStyleSets, ThemeProvider } from '@fluentui/react';
import LoginBackground from './images/login-bg.jpg';

interface IProps { }

export default class IndeTheme extends React.Component<IProps> {

    public render = () => 
        <ThemeProvider theme={theme}>
            <div className={css.background}>
                {this.props.children}
            </div>
        </ThemeProvider>
}

const css = mergeStyleSets({
    background: {
        minHeight: '100vh',
        background: `url(${LoginBackground}) no-repeat center center fixed`,
        '-webkit-background-size': 'cover',
        '-moz-background-size': 'cover',
        '-o-background-size': 'cover',
        'background-size': 'cover'  
    }
});