import * as React from 'react';
import SignIn from '../SignIn';
import { Spinner, mergeStyleSets } from '@fluentui/react';

interface IProps { }

interface IState { 
    timedOut?: boolean;
}

export default class SigningIn extends React.Component<IProps, IState> {

    public state: IState = {};

    private timeout: number | undefined;

    public componentDidMount = () => this.timeout = window.setTimeout(this.loginTimeout, 5000);

    public componentWillUnmount = () => window.clearTimeout(this.timeout);

    public render = () => {
        return (
            <SignIn>
                <Spinner label="Signing in" />
                {this.renderTimedOut()}
            </SignIn>
        );
    }

    private renderTimedOut = () => {

        if (!this.state.timedOut) {
            return null;
        }

        return (
            <div className={css.timeout}>
                Signing in is taking longer than expected, you can {' '}
                <a  href="/"
                    onClick={this.retrySignIn}
                    className={css.anchor}
                >
                    click here
                </a> to try again.
            </div>
        );
    }

    private loginTimeout = () => this.setState({ timedOut: true });

    private retrySignIn = () => {
        sessionStorage.clear();
        localStorage.clear();
        document.location.replace('/');
    }
}

const css = mergeStyleSets({
    anchor: {
        cursor: 'pointer'
    },
    timeout: {
        marginTop: 15,
        padding: 15,
        background: 'whitesmoke'
    }
})
