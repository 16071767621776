import * as React from 'react';
import { Loading } from '../layout/Loading';
import { getApi, Api } from './Api';
import ValidationSummary from '../form/ValidationSummary';
import { ActionButton } from '@fluentui/react';

interface IProps<TModel> {
    onGetModel: (api: Api, requestInit: RequestInit) => Promise<TModel>;
    onRenderModel?: (model: TModel) => React.ReactNode;
    loadingTemplate?: React.ReactNode;
}

interface IState<TModel> {
    model?: TModel;
    errors?: {};
}

export default class ApiQuery<TModel> extends React.PureComponent<IProps<TModel>, IState<TModel>> {

    public state = {} as IState<TModel>;
    private readonly api = getApi();
    
    private abortController = new AbortController();

    public componentDidMount = () => this.loadModel();

    public componentWillUnmount = () => this.abortController.abort();

    public render() {
        const { model, errors } = this.state;

        if (errors) {
            return (
                <ValidationSummary 
                    errors={errors}
                    isMultiline={false}
                    actions={
                        <ActionButton
                            iconProps={{ iconName: 'Refresh' }}
                            onClick={this.loadModel}
                        />
                    }
                />
            );
        }

        if (model) {
            if (this.props.onRenderModel) {
                return this.props.onRenderModel(model);
            }

            return null;
        }

        return this.props.loadingTemplate || <Loading />;
    }

    public loadModel = async () => {        
        try {
            this.setState({ model: undefined, errors: undefined });
            const init = { signal: this.abortController.signal };
            const model = await this.props.onGetModel(this.api, init);
            this.setState({ model });
        } catch (errors) {
            this.setState({ errors });
        }
    }
}