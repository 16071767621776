import * as React from 'react';
import { H1 } from '../../layout/Headings';
import { IAppUser } from '../../api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from '../../state/IAppState';
import { mergeStyleSets, FontWeights } from '@fluentui/react';
import ApiQuery from '../../api/ApiQuery';
import { Api } from '../../api/Api';
import { ICompanyIndeServiceModel } from '../../api/models/ICompanyIndeServiceModel';

interface IProps { 
    appUser: IAppUser;
    outerClassName: string;
}

class CompanyIndeServices extends React.Component<IProps> {

    public render = () => {
        return (
            <ApiQuery<ICompanyIndeServiceModel[]>
                key={this.props.appUser.id}
                onRenderModel={this.renderServices}
                onGetModel={this.getModel}
            />
        );
    }

    private getModel = async (api: Api) => {
        return await api.companies.getIndeServicesAsync(this.props.appUser.companyId);
    }

    private renderServices = (services: ICompanyIndeServiceModel[]) => {
        if (!services.length) {
            return null;
        }

        return (
            <div className={this.props.outerClassName}>
                <H1>Your Inde services</H1>
                {services.map(s => <div key={s.name}>
                    <div className={css.name}>{s.name}</div>
                    <div className={css.info}>{s.information}</div>
                </div>)}
            </div>
        );
    }
}

const css = mergeStyleSets({
    name: {
        fontWeight: FontWeights.semibold,
        marginBottom: '5px'
    },
    info: {
        whiteSpace: 'pre-wrap',
        marginBottom: '10px'
    }
});

export default connect(
    (state: IAppState) => ({ appUser: state.userAppState.signedInUser! })
)(CompanyIndeServices)