import * as React from 'react';
import { H1 } from '../../layout/Headings';
import { IAppUser } from '../../api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from '../../state/IAppState';
import { IContactModel } from '../../api/models/IContactModel';
import { mergeStyleSets, FontWeights } from '@fluentui/react';
import { theme } from '../../theme';
import ApiQuery from '../../api/ApiQuery';
import { Api } from '../../api/Api';
import { ICompanyModel } from '../../api/models/ICompanyModel';
import { Shimmer, ShimmerGap } from '@fluentui/react';

interface IProps { 
    appUser: IAppUser;
}

class CompanyLeads extends React.Component<IProps> {

    private apiQuery: ApiQuery<IContactModel[]> | null = null;
    private company: ICompanyModel | undefined;

    public componentDidUpdate = (prevProps: IProps) => {
        if (prevProps.appUser !== this.props.appUser) {
            this.apiQuery!.loadModel();
        }
    }

    public render = () => {
        return (
            <>
                <H1>Your Inde contacts</H1>     
                <ApiQuery<IContactModel[]>
                    ref={ref => this.apiQuery = ref}
                    onRenderModel={this.renderLeadContacts}
                    onGetModel={this.getModel}
                    loadingTemplate={
                        <>
                            <Shimmer />
                            <ShimmerGap />
                            <Shimmer />
                            <ShimmerGap />
                            <Shimmer />                        
                        </>
                    }
                />
            </>
        );
    }

    private getModel = async (api: Api, init: RequestInit) => {
        this.company = await api.companies.getAsync(this.props.appUser.companyId);
        const contacts = await api.contacts.searchAsync(
            {
                userIds: [
                    ...this.company.leads.map(o => o.userId),
                    this.company.ownerUserId
                ]
            },
            init
        );
        return contacts;
    }

    private renderLeadContacts = (contacts: IContactModel[]) => {
        const ce = contacts.find(o => o.userId === this.company!.ownerUserId);
        return (
            <>
                {this.company!.leads.map(lead => {
                    const contact = contacts.find(o => o.userId === lead.userId);
                    if (contact) {
                        return this.renderContact(contact);
                    }
                    return null;
                })}
                {ce && this.renderContact(ce)}
            </>
        );
    }

    private renderContact = (contact: IContactModel) => 
        <div className={css.lead} key={contact.id}>
            <div className={css.leadName}>
                {contact.name}
            </div>
            <div>{contact.phoneMobile || contact.phoneWork || contact.emailAddress}</div>
        </div>    
}

const css = mergeStyleSets({
    lead: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: theme.spacing.s1
    },
    leadName: {
        fontWeight: FontWeights.semibold
    },
    accountLead: {
        fontWeight: FontWeights.semilight
    }
});

export default connect(
    (state: IAppState) => ({ appUser: state.userAppState.signedInUser! })
)(CompanyLeads)