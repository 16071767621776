// ignoreGeneratorOverwrite=true
import { ISiteMapPage } from './ISiteMapPage';
import { lazy } from 'react';

const SiteMap: ISiteMapPage = {
    paths: ['/'],
    name: 'Home',
    iconName: 'Home',
    component: lazy(() => import('../pages/Homepage') as any),
    showOnMainNav: true,
    children: [
        {
            paths: ['/projects'],
            name: 'Projects',
            iconName: 'ProjectCollection',
            component: lazy(() => import('../pages/ProjectIndexPage') as any),
            showOnMainNav: true,
            children: [
                {
                    paths: ['/projects/:id', '/projects/:id/:projectName', '/orders/:id/:projectName'],
                    name: 'Project view',
                    component: lazy(() => import('../pages/ProjectIndexPage/ProjectViewPage') as any),
                    showOnMainNav: false,
                    children: []
                }                
            ]
        },      
        {
            paths: ['/orders'],
            name: 'Orders',
            iconName: 'ShoppingCart',
            component: lazy(() => import('../pages/ProjectOrderIndexPage') as any),
            showOnMainNav: true,
            children: [
                {
                    paths: ['/orders/:id', '/orders/:id/:projectName'],
                    name: 'Order view',
                    component: lazy(() => import('../pages/ProjectIndexPage/ProjectViewPage') as any),
                    showOnMainNav: false,
                    children: []
                }                    
            ]
        },           
        {
            paths: ['/jobs'],
            name: 'Jobs',
            iconName: 'TaskLogo',
            component: lazy(() => import('../pages/JobsIndexPage') as any),
            showOnMainNav: true,
            children: [
                {
                    paths: ['/jobs/:id', '/jobs/:id/:jobName'],
                    name: 'Job view',
                    component: lazy(() => import('../pages/JobsIndexPage/JobViewPage') as any),
                    showOnMainNav: false,
                }
            ]
        },
        {
            paths: ['/renewals'],
            name: 'Renewals',
            iconName: 'RenewalFuture',
            component: lazy(() => import('../pages/RenewalsIndexPage') as any),
            showOnMainNav: true,
            children: [
                {
                    paths: ['/renewals/:id',],
                    name: 'Renewal view',
                    component: lazy(() => import('../pages/RenewalsIndexPage/RenewalViewPage') as any),
                    showOnMainNav: false,
                }
            ]
        },
        {
            paths: ['/users'],
            name: 'Users',
            iconName: 'Group',
            component: lazy(() => import('../pages/UserIndexPage') as any),
            showOnMainNav: true,
            children: []
        }    
    ]
}

const mapParent = (page: ISiteMapPage, prev?: ISiteMapPage) => {

    page.parent = prev;

    if (page.children) {
        page.children.forEach(o => mapParent(o, page));
    }
}

mapParent(SiteMap);

export default SiteMap;