export interface IJobModel extends IJobEdit {
    companyName: string;    
    completedDateTime?: Date | string;
    createdDateTime: Date | string;
    isTimeRecordable: boolean;
    overnightChargesTotal: number;
    noteCount: number;
    projectId: number;
    projectName: string;
    projectServiceDescription: string;    
    subscribers: Array<{ 
        userId: number;
        name: string;
    }>
    timeEntryTotalHours: number;
    rate: number;
    requestorContactName: string;
    status: JobStatus;
    tags?: string[];
    siteIds?: number[];
}

export interface IJobEdit {
    id: number;
    assignedToDisplayName?: string;
    assignedToSkillId?: number;
    assignedToUserId?: number;
    companyId: number;
    description: string;
    dueDate?: Date | string;
    name: string;
    parentJobId?: number;
    priority: JobPriority;
    projectServiceId: number;
    projectId: number;
    requestorContactId?: number;
    requiredSkillId?: number;
    timeEstimateInHours?: number;      
}

export enum JobStatus {
    Open = 'Open',
    Completed = 'Completed',
    OpenAndCompleted = 'OpenAndCompleted',
    Merged = 'Merged'
}

export enum JobPriority {
    P1_Critical = 1,
    P2_High = 2,
    P3_Standard = 3,
    P4_Low = 4
}