import * as React from 'react';
import { H1, H3 } from './Headings';
import { mergeStyleSets, IconButton, FontWeights } from '@fluentui/react';
import { connect } from 'react-redux';
import { IAction } from '../state/actions/IAction';
import { setAdditonalPanelOpen } from '../state/actions/SettingsActions';
import { ISettingsState } from '../state/models/ISettingsState';
import { IAppState } from '../state/IAppState';
import CompanyLeads from '../controls/companies/CompanyLeads';
import CompanyIndeServices from '../controls/companies/CompanyIndeServices';

interface IProps { 
    settings: ISettingsState;
    setAdditonalPanelOpen: (open: boolean) => IAction<boolean>;
}

class Information extends React.Component<IProps> {

    public render = () => {
        return (
            <div className={css.container}>
                <div className={css.panelContainer}>
                    <div className={css.panels}>
                        <div className="panel">
                            <CompanyLeads />
                        </div>
                        <CompanyIndeServices outerClassName="panel" />
                        <div className="panel">
                            <H1>Need Help?</H1>
                            <div>
                                <H3>0800 694 633 (int. +64 3 974 1074)</H3>
                                <p>
                                    <span className={css.heading}>Auckland</span><br />
                                    Level 10, SAP House, 151 Queen Street,<br />
                                    Auckland 1010<br />
                                    0800 694 633
                                </p>
                                <p>
                                    <span className={css.heading}>Wellington</span><br />
                                    Level 5, 86 Victoria Street, Wellington 6011<br />
                                    0800 694 633
                                </p>
                                <p>
                                    <span className={css.heading}>Christchurch</span><br />
                                    175 Roydvale Avenue, Burnside, Christchurch 8053<br />
                                    0800 694 633
                                </p>
                                <p>
                                    <span className={css.heading}>Dunedin</span><br />
                                    Level 1, Trevian House, 60 Tennyson Street,<br />
                                    Dunedin 9016<br />
                                    0800 694 633
                                </p>
                                <p>
                                    <span className={css.heading}>Post</span><br />
                                    PO Box 39066,<br /> 
                                    Harewood,<br /> 
                                    Christchurch 8545<br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={css.close}>
                    <IconButton 
                        iconProps={{
                            iconName: this.props.settings.additionalPanelOpen ? 'ChevronRightEnd6' : 'ChevronLeftEnd6'
                        }}
                        onClick={this.setAdditonalPanelOpen}
                        styles={{
                            root: {
                                background: 'transparent'
                            }
                        }}
                    />
                </div>
            </div>
        )
    }

    private setAdditonalPanelOpen = () => this.props.setAdditonalPanelOpen(!this.props.settings.additionalPanelOpen);
}

export default connect(
    (state: IAppState) => ({ settings: state.settings }), 
    { setAdditonalPanelOpen }
)(Information);

const css = mergeStyleSets({
    close: {
        background: 'rgba(255, 255, 255, 0.5)',
        height: '32px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    panelContainer: {
        overflowY: 'auto',
        flex: 1
    },
    panels: {
        margin: 25,
        selectors: {
            '.additional-closed &': {
                display: 'none'
            }
        }
    },
    heading: {
        fontWeight: FontWeights.semibold
    }
});