import { JobPriority, IJobEdit, IJobModel, JobStatus } from "../IJobModel";
import moment from 'moment';

export const getJobEditDefault = (modifier?: Partial<IJobEdit>): IJobModel => {
    return {
        id: 0,
        assignedToDisplayName: '',
        companyId: 0,
        companyName: '',
        createdDateTime: new Date(),
        description: '',
        dueDate: moment().endOf('day').add(3, 'days').toDate(),
        isTimeRecordable: true,
        name: '',
        noteCount: 0,
        overnightChargesTotal: 0,
        priority: JobPriority.P3_Standard,
        projectId: 0,
        projectName: '',
        projectServiceId: 0,
        projectServiceDescription: '',
        rate: 0,
        requestorContactName: '',
        subscribers: [],
        status: JobStatus.Open,
        timeEntryTotalHours: 0,
        ...modifier        
    };
}