import * as React from 'react';
import ValidationSummary from './ValidationSummary';
import { Layer } from '@fluentui/react';
import { RepositoryBase } from '../api/RepositoryBase';
import { IModel } from '../api/models/IModel';

export interface IRenderFormProps<TFormModel extends IModel> {
    model: TFormModel;
    parentKeys: string[];
    onValueChanged: (modify: (model: TFormModel) => void) => void;
    getErrorMessage: (keys: string[]) => undefined | string,
    clearErrorMessages: (keys: string[]) => void,
}

export interface IFormBaseProps<TFormModel extends IModel, TModel extends IModel> {
    model: TFormModel;
    repository: RepositoryBase<TModel>;
    onRenderForm: (props: IRenderFormProps<TFormModel>) => React.ReactNode;
    disabled?: boolean;
}

export interface IFormBaseState<TFormModel> {
    modified: TFormModel;
    original: TFormModel;
    loading?: boolean;
    errors?: any;
}

export default abstract class FormBase<
    TProps extends IFormBaseProps<TFormModel, TModel>,
    TState extends IFormBaseState<TFormModel>,
    TFormModel extends IModel, 
    TModel extends IModel
> extends React.Component<
    TProps, 
    TState
> {

    protected registeredErrorKeys: string[] = [];

    constructor(props: TProps) {
        super(props);

        this.state = this.getState(props.model);
    }

    public componentDidUpdate() {
        const { model } = this.props;
        if (model !== this.state.original as TFormModel) {
            this.setState(this.getState(model));
        }
    }

    public render() {

        const { errors } = this.state;

        return (
            <>
                {errors && this.renderErrorSummary(errors)}                
                {this.props.onRenderForm({
                    model: this.state.modified, 
                    onValueChanged: this.update, 
                    getErrorMessage: this.getErrorMessage,
                    clearErrorMessages: this.clearErrorMessages,
                    parentKeys: []
                })}
            </>
        );
    }

    protected getDeepCopy(model: TFormModel): TFormModel {
        return JSON.parse(JSON.stringify(model));
    }

    protected isDate(o: any) {
        return o instanceof Date || (typeof(o) === 'string' && new Date(o));
    }

    private renderErrorSummary = (errors: any) => 
        <Layer>
            <ValidationSummary
                errors={errors}
                keysToIgnore={this.registeredErrorKeys}
                messageForNoMatched="Please check the form for errors."
            />
        </Layer>

    private getState = (model: TFormModel): TState => {
        return {
            modified: this.getDeepCopy(model),
            original: model
        } as TState;
    }

    private findErrorKey = (keys: string[]) => {
        const { errors } = this.state;
        if (errors) {
            const errorKeys = Object.keys(errors);
            for (const errorKey of errorKeys) {
                const keyToFind = keys.join('.');

                if (errorKey.toLowerCase() === keyToFind.toLowerCase()) {
                    return errorKey;
                }
            }
        }   

        return undefined;
    }

    private clearErrorMessages = (keys: string[]) => {

        let newErrors = {...this.state.errors as any}
        const errorKey = this.findErrorKey(keys);

        if (errorKey) {
            delete newErrors[errorKey];
        }

        if (Object.keys(newErrors).length === 0) {
            newErrors = undefined;
        }

        this.setState({ errors: newErrors });
    }

    private getErrorMessage = (keys: string[]): undefined | string => {
        const key = keys.join('.');
        if (!this.registeredErrorKeys.find(o => o === key)) {
            this.registeredErrorKeys.push(key);
        }

        const errorKey = this.findErrorKey(keys);
        if (errorKey) {
            const errors = this.state.errors![errorKey];
            if (Array.isArray(errors)) {
                return errors.length > 0 ? errors[0] : undefined;
            }
            return errors;
        }

        return undefined;
    }

    private update = (modify: (model: TFormModel) => void) => {
        if (!this.props.disabled) {
            const modified = this.getDeepCopy(this.state.modified) as TFormModel;
            modify(modified);
            this.setState({ modified: modified as any });
        }
    }
}