import { combineReducers } from "redux";
import commandBar from './CommandBarReducer';
import page from './PageReducer';
import userAppState from './UserReducer';
import settings from './SettingsReducer';

export default combineReducers({
    commandBar,
    page,
    settings,
    userAppState
})