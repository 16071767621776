import { MotionAnimations } from '@fluentui/react'
import { theme } from '../theme';

const FormFieldErrorMessage = (props: { error?: string }) => {
    if (props.error) {
        return (
            <div 
                className={MotionAnimations.slideDownIn}
                style={{ 
                    marginTop: 5, 
                    fontSize: '12px',
                    fontWeight: 400,
                    color: theme.semanticColors.errorText
                }}
            >
                {props.error}
            </div>
        );
    }

    return <div />;
};

export default FormFieldErrorMessage;
