import { ApiValidationError } from "./error/ApiValidationError";
import { ApiError } from "./error/ApiError";
import { IAppUser } from "./models/IAppUser";
import { ICountResult } from "./ICountResult";
import { isArray } from "util";
import { appConfig } from "../config/AppConfigProxy";
import { Permission } from "./models/Permission";

interface IModel {
    id: number;
}

export abstract class RepositoryBase<TModel extends IModel> {

    protected readonly apiPath: string;
    protected readonly signedInUser: IAppUser;
    private readonly getToken: () => Promise<string>;

    constructor(apiPath: string, signedInUser: IAppUser, getToken: () => Promise<string>) {
        this.apiPath = apiPath;
        this.signedInUser = signedInUser;
        this.getToken = getToken;
    }

    public async getAsync(id: number, init?: RequestInit) {
        const result = await this.fetchAsync<TModel>(id, init);
        return result;
    }

    public async searchAsync(search?: {}, init?: RequestInit) {
        const url = this.getQueryString(search);
        const models = await this.fetchAsync<TModel[]>(url, init);
        return models;
    }

    public async searchAndCount(search?: {}, init?: RequestInit) {
        const url = 'search-and-count' + this.getQueryString(search);
        const response = await this.fetchResponseAsync(url, init);

        if (response.ok) {
            const countHeader = response.headers.get('X-Pagination');
            if (countHeader) {
                const count = JSON.parse(countHeader) as ICountResult;
                const results = await response.json() as TModel[];

                return {
                    count,
                    results
                }
            }            
        }

        throw new ApiError(response);
    }

    public async searchAllAsync(search?: {}, onProgress?: (percent: number) => void, init?: RequestInit) {

        let searchAndCount = await this.searchAndCount(search, init);
        if (searchAndCount.results.length >= searchAndCount.count.totalCount) {
            return searchAndCount.results;
        }

        const { count } = searchAndCount;
        const pages = Array.from(Array(count.totalPages).keys());
        const results: TModel[][] = new Array(count.totalPages);
        await Promise.all(
            pages.map(async i => {
                results[i] = await this.searchAsync({
                    ...search,
                    page: i + 1
                });

                if (onProgress) {
                    const percent = getPercentage(results.filter(o => Array.isArray(o)).length, pages.length);
                    onProgress(percent);
                }
            }),
        );

        return results.reduce((a, b) => a.concat(b), []);
    }    

    public async saveAsync(model: TModel, init?: RequestInit) {

        const url = appConfig.apiUrl + '/' + this.apiPath + (model.id > 0 ? `/${model.id}` : '');
        const headers = await this.getDefaultHeaders();
        headers.push(['Content-Type', 'application/json']);

        const response = await fetch(
            url, 
            {
                ...init,
                body: JSON.stringify(model),
                headers,
                method: model.id > 0 ? 'put' : 'post'
            }
        );
    
        const result = await this.getResultAsync<TModel>(response);

        return result;
    }

    protected async fetchResponseAsync(path: string | number, init?: RequestInit) {

        const url = appConfig.apiUrl + '/' + this.apiPath + '/' + path;
        const headers = await this.getDefaultHeaders();
        
        const response = await fetch(
            url, 
            {
                ...init,
                headers
            }
        );

        return response;
    }

    protected async fetchAsync<TResult>(path: string | number, init?: RequestInit) {

        const response = await this.fetchResponseAsync(path, init);
        
        return await this.getResultAsync<TResult>(response);
    }

    protected async getResultAsync<TResult>(response: Response) {
        if (response.ok) {
            const result = await response.json() as TResult;
            return result;
        }
    
        switch (response.status) {
            case 400:
            case 422: {
                const errors = await response.json();
                throw new ApiValidationError(response, errors);
            }
    
            default:
                throw new ApiError(response);
        }
    }

    protected getQueryString = (search?: any) => {
        let query = '';

        if (search) {
            Object.keys(search).forEach((key) => {
                let val = search[key];
                if (val) {
                    if (isArray(val)) {
                        val.forEach((o) => {
                            query += `${query.length ? '&' : '?'}${key}=${o}`
                        });
                        return;
                    }
                    
                    if (val instanceof Date) {
                        val = val.toISOString();
                    }
                
                    query += `${query.length ? '&' : '?'}${key}=${val}`;
                }
            });
        }

        return query;
    };

    protected getDefaultHeaders = async (): Promise<string[][]> => {
        const token = await this.getToken();
        const headers = [['Authorization', 'Bearer ' + token]];

        const { impersonatingUser } = this.signedInUser;
        if (impersonatingUser && impersonatingUser.permissions.includes(Permission.ImpersonateClientPortalUser)) {
            headers.push(['ImpersonateUserId', this.signedInUser.id + ''])
        }

        return headers;
    }
}

export function getPercentage(numerator: number, denominator: number) {

    if (denominator === 0) {
        return 100;
    }

    if (numerator <= 0) {
        return 0;
    }

    return Math.round((numerator / denominator) * 100);
}