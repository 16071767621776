import * as React from 'react';
import { mergeStyleSets, FontWeights, IStyle } from '@fluentui/react';
import { theme } from '../theme';
import { FontSizes } from '@fluentui/react';
import { Persona, PersonaSize, Panel, PrimaryButton, Icon, MessageBar, MessageBarType } from '@fluentui/react';
import { IAppUser } from '../api/models/IAppUser';
import { connect } from 'react-redux';
import { IAppState } from '../state/IAppState';
import { H1, H2 } from './Headings';
import { getToken } from '../index';
import { RouteComponentProps, withRouter } from 'react-router';
import { initializeApi } from '../api/Api';
import { IAction } from '../state/actions/IAction';
import { userSignedIn } from '../state/actions/UserActions';

interface IProps extends RouteComponentProps { 
    appUser?: IAppUser;
    userSignedIn: (appUser: IAppUser) => IAction<IAppUser>;
}

interface IState {
    panelOpen?: boolean;
}

class UserPanel extends React.Component<IProps, IState> {

    public state = {} as IState;

    public render = () => {

        const appUser = this.props.appUser!;
        const { contactName, companyName, impersonatingUser } = appUser;

        return (
            <>
                <div className={css.container}>                    
                    <div className={css.details}>                        
                        <div className={css.name}>{contactName}</div>
                        <div className={css.company}>{companyName}</div>
                    </div>
                    <div 
                        className={css.userButton}
                        onClick={this.setPanelOpen(true)}
                    >
                        {impersonatingUser &&
                            <div className={css.impersonating}><Icon iconName="ConnectContacts" /></div>
                        }
                        {!impersonatingUser &&
                            <Persona 
                                size={PersonaSize.size40}
                                text={contactName}
                            />
                        }
                    </div>
                </div>
                <Panel
                    isOpen={this.state.panelOpen}
                    onDismiss={this.setPanelOpen()}
                >
                    <H1 style={{ margin: 0 }}>{contactName}</H1>
                    <H2>{companyName}</H2>
                    {!impersonatingUser &&
                        <PrimaryButton onClick={this.signOut}>Sign out</PrimaryButton>                    
                    }
                    {impersonatingUser &&
                        <>
                            <MessageBar messageBarType={MessageBarType.warning} styles={{ root: { marginBottom: theme.spacing.m }}}>
                                You are currently impersonating {appUser.contactName}.
                            </MessageBar>
                            <PrimaryButton onClick={this.stopImpersonating}>Stop impersonating</PrimaryButton>                    
                        </>
                    }
                </Panel>
            </>
        );
    }

    private signOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        document.location.replace('/');
    }

    private stopImpersonating = () => {
        const user = this.props.appUser!.impersonatingUser!;
        initializeApi(user, getToken);  
        this.props.userSignedIn(user);
        this.setPanelOpen()();
        this.props.history.push('/');  
        document.location.reload(); 
    }

    private setPanelOpen = (panelOpen?: boolean) => () => this.setState({ panelOpen });
}

const routed = withRouter(UserPanel);

const connected = connect(
    (state: IAppState) => ({ 
        appUser: state.userAppState.signedInUser! 
    }),
    {
        userSignedIn
    }
)(routed);

export default connected;

const name: IStyle = {
    fontSize: FontSizes.size16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    textAlign: 'right'        
};

const css = mergeStyleSets({
    name: { ...name },
    impersonating: {
        fontSize: FontSizes.size32,
        color: theme.palette.white,
        lineHeight: 40,
        marginRight: theme.spacing.m
    },
    company: {
        ...name,
        fontWeight: FontWeights.semibold,
    },
    details: {
        margin: '5px 15px 0 0',
        color: 'white',
        selectors: {
            '@media only screen and (max-width: 800px)': {
                display: 'none'
            }
        }        
    },
    container: {
        display: 'flex',
        maxWidth: '300px'
    },
    userButton: {
        padding: 5,
        maxWidth: 50,
        height: 50,
        background: theme.palette.themeDarkAlt,
        fontSize: FontSizes.size28,
        textAlign: 'center',
        boxSizing: 'border-box',
        cursor: 'pointer'
    }
});