import { UserRepository } from "./repository/UserRepository";
import { JobRepository } from "./repository/JobRepository";
import { JobNoteRepository } from "./repository/JobNoteRepository";
import { TimeEntryRepository } from "./repository/TimeEntryRepository";
import { JobNoteAttachmentRepository } from "./repository/JobNoteAttachmentRepository";
import { ProjectRepository } from "./repository/ProjectRepository";
import { ProjectComponentRepository } from "./repository/ProjectComponentRepository";
import { ProjectServiceRepository } from "./repository/ProjectServiceRepository";
import { IAppUser } from "./models/IAppUser";
import { ContactRepository } from "./repository/ContactRepository";
import { CompanyRepository } from "./repository/CompanyRepository";
import { RenewalRepository } from "./repository/RenewalRepository";
import { appConfig } from "../config/AppConfigProxy";

export class Api {

    public readonly companies: CompanyRepository;
    public readonly contacts: ContactRepository;
    public readonly jobs: JobRepository;
    public readonly jobNotes: JobNoteRepository;
    public readonly jobNoteAttachments: JobNoteAttachmentRepository;
    public readonly projects: ProjectRepository;
    public readonly projectComponents: ProjectComponentRepository;
    public readonly projectServices: ProjectServiceRepository;
    public readonly timeEntries: TimeEntryRepository;
    public readonly renewals: RenewalRepository;
    public readonly users: UserRepository;

    constructor(signedInUser: IAppUser, getToken: () => Promise<string>) {
        this.companies = new CompanyRepository('client-portal/companies', signedInUser, getToken);
        this.contacts = new ContactRepository('client-portal/contacts', signedInUser, getToken);
        this.jobs = new JobRepository('client-portal/jobs', signedInUser, getToken);
        this.jobNotes = new JobNoteRepository('client-portal/job-notes', signedInUser, getToken);
        this.jobNoteAttachments = new JobNoteAttachmentRepository('client-portal/job-note-attachments', signedInUser, getToken);
        this.projects = new ProjectRepository('client-portal/projects', signedInUser, getToken);
        this.projectComponents = new ProjectComponentRepository('NOT IMPLEMENTED', signedInUser, getToken);
        this.projectServices = new ProjectServiceRepository('NOT IMPLEMENTED', signedInUser, getToken);
        this.timeEntries = new TimeEntryRepository('client-portal/time-entries', signedInUser, getToken);
        this.renewals = new RenewalRepository('client-portal/renewals', signedInUser, getToken);
        this.users = new UserRepository('client-portal/users', signedInUser, getToken);
    }

    public static signIn = async (token: string) => {
        const url = appConfig.apiUrl + '/client-portal/users/sign-in';

        const response = await fetch(
            url,
            { headers: [['Authorization', 'Bearer ' + token]] }
        );

        if (response.ok) {
            const user = await response.json();
            return user as IAppUser;
        }
    }

}

let api: Api | undefined;

export const initializeApi = (signedInUser: IAppUser, getToken: () => Promise<string>) => {
    api = new Api(signedInUser, getToken);
    return api;
}

export const getApi = () => {
    if (!api) {
        throw new Error('Api has not been initialized.');
    }

    return api;
}
