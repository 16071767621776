import { IAction } from "../actions/IAction";
import { IUserAppState } from "../models/IUserAppState";
import { IAppUser } from "../../api/models/IAppUser";
import { USER_SIGNED_IN } from "../actions/UserActions";

const UserReducer = (state: IUserAppState = {}, action: IAction<IAppUser>): IUserAppState => {
    switch(action.type) {
        case USER_SIGNED_IN: 
            return {
                ...state,
                signedInUser: action.payload
            };
    }

    return state;
}

export default UserReducer;
