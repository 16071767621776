import * as React from 'react';
import FormField, { IFormFieldProps, IRenderFormFieldProps } from '../FormField';
import { IChoiceGroupProps, ChoiceGroup } from '@fluentui/react';
import FormFieldErrorMessage from '../FormFieldErrorMessage';
import { IModel } from '../../api/models/IModel';

interface IProps<TModel extends IModel, TKey extends keyof TModel> 
    extends IFormFieldProps<TModel, TKey>, IChoiceGroupProps {    
}

export default class FormChoiceGroup<
    TModel extends IModel, 
    TKey extends keyof TModel
> extends React.Component<IProps<TModel, TKey>> {
    public render() {
        return (
            <FormField
                {...this.props}
                renderField={(fieldProps: IRenderFormFieldProps<TModel, TKey>) => (
                    <>
                        <ChoiceGroup 
                            selectedKey={fieldProps.value + ''}
                            onChange={(e, opt) => fieldProps.onValueChanged(opt && opt.key as any)}
                            {...this.props}
                        />
                        <FormFieldErrorMessage error={fieldProps.error} />
                    </>
                )}
            /> 
        );
    }
}