import moment from 'moment';
import { ComboBox, IButtonProps, IChoiceGroupOption, IComboBoxOption, Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { getApi } from '../../api/Api';
import ApiGet from '../../api/ApiGet';
import { IJobModel, JobPriority } from '../../api/models/IJobModel';
import { IProjectModel, ProjectStatus } from '../../api/models/IProjectModel';
import { IProjectServiceModel } from '../../api/models/IProjectServiceModel';
import FormChoiceGroup from '../../form/controls/FormChoiceGroup';
import FormDatePicker from '../../form/controls/FormDatePicker';
import FormTextField from '../../form/controls/FormTextField';
import Form from '../../form/Form';
import { IRenderFormProps } from '../../form/FormBase';
import { theme } from '../../theme';
import ProjectComboBox from '../projects/ProjectComboBox';
import PropertyView from '../PropertyView';

interface IProps { 
    onJobSaved?: (job: IJobModel) => void;
}

interface IState {
    project?: IProjectModel;
    projectService?: IProjectServiceModel;
    job?: IJobModel;
    dueDateManuallySet?: boolean;
}

class JobEditPanel extends React.Component<IProps, IState> {

    public state = {} as IState;

    public render = () => {

        const { job } = this.state;

        if (!job) {
            return null;
        }

        const repository = getApi().jobs;

        return (
            <Panel
                isOpen={true}
                onDismiss={this.dismiss}
                type={PanelType.medium}
                headerText={job.id > 0 ? `Edit job - J${job.id}` : 'New job'}
            >
                <Form<IJobModel>
                    model={job}
                    repository={repository}
                    onModelSaved={this.modelSaved}
                    onRenderForm={this.renderProjectSelection}
                    onRenderSaveButton={this.renderSaveButton}
                    onRenderCancelButton={this.renderCancelButton}
                    onCancel={this.dismiss}
                />
            </Panel>
        );
    }

    public editJob = (job?: IJobModel) => this.setState({ job });

    public dismiss = () => this.setState({
        job: undefined,
        project: undefined,
        projectService: undefined
    });

    private modelSaved = (job: IJobModel) => {
        if (this.props.onJobSaved) {
            this.props.onJobSaved(job);
        }
        
        this.dismiss();
    }

    private renderCancelButton = (props: IButtonProps, defaultRender?: (props: IButtonProps) => React.ReactNode) => {
        if (this.state.projectService) {
            return defaultRender && defaultRender(props);
        }   
    }

    private renderSaveButton = (save: () => Promise<IJobModel | null>, props: IButtonProps, defaultRender?: (props: IButtonProps) => React.ReactNode) => {
        if (this.state.projectService) {
            return defaultRender && defaultRender(props);
        }        
    }

    private renderProjectSelection = (props: IRenderFormProps<IJobModel>) => {

        const job = props.model;
        const { project, projectService } = this.state;

        return (
            <>
                {job.id <= 0 &&
                    <>
                        <div style={{ marginBottom: theme.spacing.s1 }}>
                            <ProjectComboBox 
                                search={{ hasServices: true, status: ProjectStatus.Approved }}
                                label="Project"
                                placeholder="Choose a project"
                                onModelChange={this.projectComboBoxChange(props)}
                                selectedModel={this.state.project}
                            />   
                        </div>
                        <div style={{ marginBottom: theme.spacing.s1 }}>
                            <ComboBox 
                                label="Service"
                                placeholder="Choose a service"
                                options={this.getServiceOptions(project)}
                                selectedKey={projectService && projectService.id}
                                disabled={!project}
                                onChange={this.projectServiceComboBoxChange(props)}
                            />   
                        </div>  
                    </>    
                }      
                {job.id > 0 &&
                    <>
                        <ApiGet<IProjectModel>
                            repository={getApi().projects}
                            id={job.projectId}
                            onModelLoaded={this.projectLoaded(job)}
                        />
                        {project && projectService &&
                            <> 
                                <PropertyView
                                    label="Project"
                                    content={project.name}
                                />     
                                <PropertyView
                                    label="Service"
                                    content={projectService.description}
                                />                                                         
                            </>
                        }
                    </>
                }
                {projectService && this.renderForm(props)}
            </>
        );
    }

    private projectLoaded = (job: IJobModel) => (project: IProjectModel) => {
        const projectService = project.services.find(o => o.id === job.projectServiceId);
        this.setState({ project, projectService });
    }

    private renderForm = (props: IRenderFormProps<IJobModel>) => {
        return (
            <>
                <FormTextField
                    {...props}
                    modelKey="name"
                    autoFocus={true}
                    label="Name"
                />
                <FormTextField
                    {...props}
                    modelKey="description"
                    label="Description"
                    multiline={true}
                    rows={5}
                    autoAdjustHeight={true}
                    styles={{
                        field: {
                            maxHeight: 300,
                            overflowY: 'scroll'
                        },
                    }}
                />          
                <FormDatePicker
                    {...props}
                    modelKey="dueDate"
                    label="Due"
                    showRelativeTime={true}
                    onSelectDate={this.dueDateSelectDate(props)}
                />
                <FormChoiceGroup
                    {...props}
                    modelKey="priority"
                    label="Priority"
                    options={[
                        {
                            key: JobPriority.P1_Critical + '',
                            text: 'P1 - Critical'
                        },
                        {
                            key: JobPriority.P2_High + '',
                            text: 'P2 - High'
                        },
                        {
                            key: JobPriority.P3_Standard + '',
                            text: 'P3 - Standard'
                        },
                        {
                            key: JobPriority.P4_Low + '',
                            text: 'P4 - Low'
                        },                                                                        
                    ]}
                    onChange={this.priorityChoiceGroupChange(props)}
                />
            </>
        );
    }

    private dueDateSelectDate = (props: IRenderFormProps<IJobModel>) => (date?: Date | null) => {
        this.setState({ dueDateManuallySet: true });
        props.onValueChanged(o => o.dueDate = date || undefined)
    }

    private priorityChoiceGroupChange = (props: IRenderFormProps<IJobModel>) => (e: any, option?: IChoiceGroupOption) => {
        const { dueDateManuallySet } = this.state;
        const priority = option ? option.key as any : JobPriority.P3_Standard
        props.onValueChanged(o => {
            o.priority = priority;
            if (!dueDateManuallySet) {
                const date = this.getDateFromJobPriority(priority + '');
                o.dueDate = date;
            }
        });
    }

    private getDateFromJobPriority = (priority: string) => {
        switch (priority) {
            case JobPriority.P1_Critical + '': return moment().add(4, 'hours').toDate();
            case JobPriority.P2_High + '': return moment().endOf('day').add(1, 'day').toDate();
            case JobPriority.P4_Low + '': return moment().endOf('day').add(7, 'days').toDate();
            default: return moment().endOf('day').add(3, 'days').toDate();
        }
    }

    private getServiceOptions = (project?: IProjectModel): IComboBoxOption[] => {
        if (project) {
            return project.services.map(service => ({
                key: service.id,
                text: service.description
            }))
        }

        return [];
    }

    private projectComboBoxChange = (props: IRenderFormProps<IJobModel>) => (project?: IProjectModel) => {

        let projectService: IProjectServiceModel | undefined;
        if (project && project.services.length === 1) {
            projectService = project.services[0];
            props.onValueChanged(o => o.projectServiceId = projectService!.id);
        }

        this.setState({ project, projectService });
    }

    private projectServiceComboBoxChange = (props: IRenderFormProps<IJobModel>) => (e: any, option?: IComboBoxOption) => {

        let projectService: IProjectServiceModel | undefined;

        if (option) {
            const { project } = this.state;
            projectService = project!.services.find(o => o.id === option.key);
            props.onValueChanged(o => o.projectServiceId = projectService!.id);
        }

        this.setState({ projectService });
    }

}

export default JobEditPanel;