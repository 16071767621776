import { IProjectComponentModel } from "./IProjectComponentModel";
import { IProjectServiceModel } from "./IProjectServiceModel";

export interface IProjectModel {
    id: number;
    approvedByContactName: string;
    approvedDateTime?: string | Date;
    name: string;
    createdDateTime: string | Date;
    description: string;
    companyId: number;
    components: IProjectComponentModel[];
    endDate: string | Date;
    ownerUserId: number;
    ownerUserName: string;
    purchaseOrderNumber?: string;
    quoteExpiryDate: string | Date;
    requestedByContactId: number;
    requestedByContactName: string;
    startDate: string | Date;
    services: IProjectServiceModel[];
    status: ProjectStatus;
}

export enum ProjectStatus {
    Draft = "Draft",
    PeerReview = "PeerReview",
    CustomerApproval = "CustomerApproval",        
    Approved = "Approved",
    Completed = "Completed"
}