import * as React from 'react';
import { IRenderFormProps } from './FormBase';
import { theme } from '../theme';
import { IModel } from '../api/models/IModel';

export interface IRenderFormFieldProps<TModel, TKey extends keyof TModel> {
    value: TModel[TKey], 
    onValueChanged: (value: TModel[TKey]) => void,
    label?: string,
    error?: string
}

export interface IFormFieldProps<TModel extends IModel, TKey extends keyof TModel> extends IRenderFormProps<TModel> {
    label?: string;
    modelKey: TKey;
    renderField?: (props: IRenderFormFieldProps<TModel, TKey>) => React.ReactNode;    
}

export default function FormField<TModel extends IModel, TKey extends keyof TModel>(
    { renderField, modelKey, model, parentKeys, onValueChanged, clearErrorMessages, getErrorMessage, label }: IFormFieldProps<TModel, TKey>) {

    function getValue(model: TModel, key: TKey) {
        if (model[key] && (model[key] as any).hasOwnProperty('op')) {
            return (model[key] as any).value;
        }

        return model[key];
    }

    function setValue(model: TModel, key: TKey, value: any) {
        if (model[key] && (model[key] as any).hasOwnProperty('op')) {
            (model[key] as any).value = value;
        } else {
            model[key] = value;
        }
    }

    function onChanged(value: TModel[TKey]) {
        onValueChanged(o => setValue(o, modelKey, value));
        clearErrorMessages([...parentKeys, modelKey + '']);
    }

    return (
        <div style={{ marginBottom: theme.spacing.s1 }}>
            {renderField && renderField({
                value: getValue(model, modelKey), 
                onValueChanged: onChanged, 
                label: label,
                error: getErrorMessage([...parentKeys, modelKey + ''])
            })}
        </div>
    )
}
