import * as React from 'react';
import { ISiteMapPage } from './ISiteMapPage';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { commandBarReset } from '../state/actions/CommandBarActions';
import { IUserAppState } from '../state/models/IUserAppState';
import { IAppState } from '../state/IAppState';
import { pageChange } from '../state/actions/PageActions';
import { H1 } from '../layout/Headings';

interface IProps extends RouteComponentProps<{}> {
    /** Page is passed in via SiteMapRoutes (not redux) */
    page: ISiteMapPage;
    pageChange: typeof pageChange;
    commandBarReset: typeof commandBarReset;
    userAppState: IUserAppState;
    children: JSX.Element[] | JSX.Element;
}

function PageWrapper(props: IProps) {

    React.useEffect(() => {
        document.title = props.page.title || props.page.name; 
        props.pageChange(props.page);
        return function cleanUp() {
            props.commandBarReset();
        };
    });

    const output = React.useMemo(() => {
        const { children } = props;
        const { signedInUser } = props.userAppState;

        if (!signedInUser || !signedInUser.hasClientLogin) {
            return (
                <>
                    <H1>403 - Forbidden</H1>
                    <div>
                        You do not have the correct roles to view this page.
                    </div>
                </>
            );
        }

        return <>{children}</>;
    }, [props]);

    return output;
}

export default connect(
    (state: IAppState) => ({ userAppState: state.userAppState }), 
    { 
        pageChange, 
        commandBarReset
    }
)(withRouter(PageWrapper));