import { RepositoryBase } from "../RepositoryBase";
import { IAttachmentModel } from "../models/IAttachmentModel";
import { appConfig } from "../../config/AppConfigProxy";

export class JobNoteAttachmentRepository extends RepositoryBase<IAttachmentModel> {
    
    public uploadFile = async (file: FormData, init?: RequestInit) => {
        const headers = await this.getDefaultHeaders();
        const url = appConfig.apiUrl + '/client-portal/job-note-attachments/upload';
        const response = await fetch(
            url, 
            {
                ...init,
                body: file,
                headers,
                method: 'post'
            }
        );

        return response.json();
    }

}