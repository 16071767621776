import * as React from 'react';
import FormField, { IFormFieldProps, IRenderFormFieldProps } from '../FormField';
import { IDatePickerProps, DatePicker } from '@fluentui/react';
import FormFieldErrorMessage from '../FormFieldErrorMessage';
import { Label, FontWeights } from '@fluentui/react';
import moment from 'moment';
import { IModel } from '../../api/models/IModel';

export interface IFormDatePickerProps<TModel extends IModel, TKey extends keyof TModel> 
    extends IFormFieldProps<TModel, TKey>, IDatePickerProps {
    preserveTime?: boolean;
    showRelativeTime?: boolean;
}

export default class FormDatePicker<
    TModel extends IModel, 
    TKey extends keyof TModel,
> extends React.Component<IFormDatePickerProps<TModel, TKey>> {

    public render() {

        const { label } = this.props;

        return (
            <FormField
                {...this.props}
                renderField={(fieldProps: IRenderFormFieldProps<TModel, TKey>) => {
                    const { error } = fieldProps;
                    const currentDate = this.getDate(fieldProps.value);
                    return (
                        <div className={error ? 'error' : ''}>
                            {label &&
                                <Label>
                                    {label}
                                    {this.getRelativeTime(currentDate)}
                                </Label>
                            }
                            <DatePicker 
                                value={currentDate}
                                onSelectDate={this.setDate(currentDate, fieldProps.onValueChanged)}
                                style={{ width: 200 }}
                                {...this.props}
                                label={undefined}
                            />
                            <FormFieldErrorMessage error={error} />
                        </div>
                    );
                }}
            /> 
        );
    }

    private getRelativeTime = (date: any) => {
        const { showRelativeTime } = this.props;
        if (!showRelativeTime) {
            return undefined;
        }

        const relative = moment(date).fromNow();

        return (
            <span style={{ fontWeight: FontWeights.semilight as any }}> ({relative})</span>
        )
    }

    private setDate = (currentDate: Date | null | undefined, onValueChanged: (value: TModel[TKey]) => void) => (date: Date | null | undefined) => {

        if (currentDate && date && this.props.preserveTime) {
            date.setHours(currentDate.getHours());
            date.setMinutes(currentDate.getMinutes());
            date.setSeconds(currentDate.getSeconds());
            date.setMilliseconds(currentDate.getMilliseconds());
        }

        onValueChanged(date ? date : undefined as any);
    }

    private getDate = (date: any) => {
        if (typeof(date) === 'string') {
            return new Date(date);
        } else if (date instanceof Date) {
            return date;
        }

        return undefined;
    }
}