import { Component } from 'react';
import IndeTheme from './IndeTheme';
import SiteLayout from './layout/SiteLayout';

interface IProps {
}

export default class App extends Component<IProps> {

    public render() {
        return (
            <IndeTheme>
                <SiteLayout />
            </IndeTheme>
        );
    }
}